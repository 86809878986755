#wrapper {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	justify-content: center;
	text-align: center;
}

body {
	color: white;
	display: flex;
	flex-flow: column;
}

.center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.content span {
	display: inline-block;
}

#end_time {
	font-size: 150%;
	padding: 20px 0;
	font-weight: bold;
}

/* body, #wrapper {
	background-color: #c34614;
} */

.progress_label {
	font-size: small;
}

#progress_parent {
	border-radius: 10px;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.25);
	border-width: 1px;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.25) inset;
	overflow: hidden;
	display: none;
}

.progress_bar {
	text-align: left;
	display: block;
	border: none;
	width: 100%;
	/* border-radius: 10px; */
	height: 20px;
	box-sizing: border-box;
}

.progress_bar:not(:last-child) {
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: var(--background_text_color);
}

.progress_bar::before {
	background-color: var(--background_text_color);
	display: block;
	height: 100%;
	content: '';
	width: var(--width);
	/* border-radius: 10px; */
}

#current_parent div,
#next_period {
	padding: 10px 0px;
}

#next_parent {
	display: none;
}

#current_parent div .text-wrapper {
	vertical-align: middle;
	display: inline;
}

#current_parent .progress {
	display: inline-flex;
	padding: 0px !important;
	vertical-align: middle;
	box-sizing: border-box;
}

#current_parent .progress-ring {
	display: inline;
	padding: 0px !important;
	vertical-align: middle;
}

#schedulename {
	font-size: 150%;
	font-weight: bold;
	padding-bottom: 50px;
	display: none;
}
