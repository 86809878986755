#wrapper {
  text-align: center;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

body {
  color: #fff;
  flex-flow: column;
  display: flex;
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.content span {
  display: inline-block;
}

#end_time {
  padding: 20px 0;
  font-size: 150%;
  font-weight: bold;
}

.progress_label {
  font-size: small;
}

#progress_parent {
  border: 1px solid #00000040;
  border-radius: 10px;
  display: none;
  overflow: hidden;
  box-shadow: inset 0 0 5px 5px #00000040;
}

.progress_bar {
  text-align: left;
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 20px;
  display: block;
}

.progress_bar:not(:last-child) {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--background_text_color);
}

.progress_bar:before {
  background-color: var(--background_text_color);
  content: "";
  width: var(--width);
  height: 100%;
  display: block;
}

#current_parent div, #next_period {
  padding: 10px 0;
}

#next_parent {
  display: none;
}

#current_parent div .text-wrapper {
  vertical-align: middle;
  display: inline;
}

#current_parent .progress {
  vertical-align: middle;
  box-sizing: border-box;
  display: inline-flex;
  padding: 0 !important;
}

#current_parent .progress-ring {
  vertical-align: middle;
  display: inline;
  padding: 0 !important;
}

#schedulename {
  padding-bottom: 50px;
  font-size: 150%;
  font-weight: bold;
  display: none;
}

/*# sourceMappingURL=index.9cd7e481.css.map */
